import { rest } from 'msw'
import config from '~/config'
import { emptyEvents, events } from '~/__mocks__/api/fake-data/events'

const apiBaseUrl = `${config.api.baseURL}/v1/admin/projects/`

const createNewEvent = (name: string, eventId: string) => ({
  isDeleted: false,
  isActive: false,
  _id: '6308c22d1166f00020460316',
  name,
  eventId,
  domain: 'gleamer.io',
  admin: '62ea8ad93efeab46001416da',
  otApiKey: '47561661',
  createdAt: '2022-08-26T12:53:01.991Z',
  updatedAt: '2022-08-26T12:53:17.341Z',
  __v: 0,
  id: '6308c22d1166f00020460316',
})

const getEventsByUser = rest.get(`${apiBaseUrl}by-admin`, (_req, res, ctx) => {
  const noData = window.history.state.url?.includes('no-data=true')
  if (noData) {
    return res(ctx.status(200), ctx.json(emptyEvents))
  }
  return res(ctx.status(200), ctx.json(events))
})

const createEvent = rest.post(`${apiBaseUrl}new-gleamer`, (_req, res, ctx) => {
  // @ts-ignore
  const noData = window.history.state.url?.includes('no-data=true')

  const { name, eventId }: any = _req.body
  if (name && eventId) {
    const event = createNewEvent(name, eventId)
    if (noData) {
      emptyEvents.push(event)
    } else {
      events.push(event)
    }
    return res(ctx.status(200), ctx.json(event))
  }
  return res(ctx.status(400))
})

const getOneEvent = rest.get(`${apiBaseUrl}:id/for-admin`, (_req, res, ctx) => {
  const eventId = _req.params.id
  const searchedEvent = events.find((event: any) => event.id === eventId)
  if (searchedEvent) {
    return res(ctx.status(200), ctx.json(searchedEvent))
  }
  return res(ctx.status(404))
})
const updateEvent = rest.patch(
  `${apiBaseUrl}update/:id/for-admin`,
  (_req, res, ctx) => {
    const eventId = _req.params.id
    const searchedEventIndex = events.findIndex(
      (event: any) => event.id === eventId,
    )
    if (searchedEventIndex > -1) {
      events[searchedEventIndex] = {
        ...events[searchedEventIndex],
        ...(_req.body as any),
      }
      return res(ctx.status(200), ctx.json(events[searchedEventIndex]))
    }
    return res(ctx.status(404))
  },
)
const deleteEvent = rest.delete(
  `${apiBaseUrl}delete/:id/for-admin`,
  (_req, res, ctx) => {
    const eventId = _req.params.id
    const searchedEventIndex = events.findIndex(
      (event: any) => event.id === eventId,
    )
    if (searchedEventIndex > -1) {
      const deletedEvent = events.splice(searchedEventIndex, 1)[0]
      return res(ctx.status(200), ctx.json(deletedEvent))
    }
    return res(ctx.status(404))
  },
)

export const eventsHandler = [
  getEventsByUser,
  createEvent,
  getOneEvent,
  updateEvent,
  deleteEvent,
]
