import '~/styles/tailwind.css'
// eslint-disable-next-line no-unused-vars
import type { AppProps } from 'next/app'
import { useSetup } from '@coding4tomorrow/c4t-next-core'
import config from '../config'
import apiRoutes from '../apiRoutes'
import Head from 'next/head'
import { startMswServer } from '~/__mocks__/api'
import { Alert } from 'antd'

startMswServer()
const App = ({ Component, pageProps }: AppProps) => {
  useSetup({
    api: {
      baseURL: config.api.baseURL,
      routes: apiRoutes,
    },
  })
  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
      </Head>
      {false && <Alert />}
      <Component {...pageProps} />
    </>
  )
}

export default App
