const apiRoutes = ({
  get,
  post,
  patch,
  // put,
  del,
}: any) => ({
  users: {
    me: get('/v1/admin/users/me'),
    updateAdmin: patch('/v1/admin/users/update-admin'),
    deleteAdminConfirmation: del('/v1/admin/users/delete-admin-confirmation'),
    deleteAdmin: del('/v1/admin/users/delete-admin'),
    deleteUser: del('/v1/admin/users/:id/delete-user/stages/:stageId'),
    getUsersByStage: get('/v1/admin/users/by-stage/:id'),
    getUsersByEvent: get('/v1/admin/users/findUsersByProject/:id'),
    create: post('/v1/admin/users/create'),
    createForEvent: post('/v1/admin/users/create/for-event'),
    getOne: post('/v1/admin/users/:id'),
    updateUser: patch('/v1/admin/users/:id/update'),
    getNonAddedUsers: get('/v1/admin/users/non-added/stages/:id'),
    addExistingUsers: post('/v1/admin/users/add-existing/stages/:id'),
    getAllUsersPerStage: get('/v1/admin/users/event/:id/users-per-stage'),
    createFromCSV: post('/v1/admin/users/create/from-csv/stages/:id'),
    getLoginHistory: get('/v1/admin/users/login-history/event/:id'),
  },
  payment: {
    getSetupIntentClientSecret: get(
      '/v1/admin/users/stripe/setup-intent/client-secret',
    ),
    getAddedCardsList: get('/v1/admin/users/stripe/customer/cards'),
    deleteCustomerCard: del(
      '/v1/admin/users/stripe/customer/payment-method/delete/:id',
    ),
  },
  auth: {
    registerGleam: post('/v1/admin/auth/register-gleam'),
    login: post('/v1/admin/auth/login-gleam'),
    forgotPassword: post('/v1/admin/auth/forgot-password'),
    resetPassword: patch('/v1/admin/auth/reset-password'),
    changePassword: patch('/v1/admin/auth/change-password'),
    switchToPayAsYouGo: patch('/v1/admin/auth/status/pay-as-you-go'),
  },
  projects: {
    create: post('/v1/admin/projects/new-gleamer'),
    getProjectsByUser: get('/v1/admin/projects/by-admin'),
    getOneProjectForUser: get('/v1/admin/projects/:id/for-admin'),
    updateProjectForUser: patch('/v1/admin/projects/update/:id/for-admin'),
    updateThankYou: patch('/v1/admin/projects/update/:id/thank-you'),
    updateTicketOffice: patch('/v1/admin/projects/update/:id/ticket-office'),
    deleteProjectForUser: del('/v1/admin/projects/delete/:id/for-admin'),
    branding: patch('/v1/admin/projects/:id/branding'),
    canPublishProject: get('/v1/admin/projects/:id/can-publish'),
    publishProject: post('/v1/admin/projects/:id/publish'),
    unpublishProject: post('/v1/admin/projects/:id/unpublish'),
    sendEmails: post('/v1/admin/projects/:id/send-emails'),
  },
  stages: {
    getStagesByEvent: get('/v1/admin/groups/event/:id'),
    delete: del('/v1/admin/groups/:id/stage'),
    create: post('/v1/admin/groups/gleamer/create'),
    update: post('/v1/admin/groups/gleamer/update/:id'),
    getOne: get('/v1/admin/groups/gleamer/get-one/:id'),
    uploadFile: post('/v1/admin/groups/upload', null, {
      'Content-Type': 'multipart/form-data',
    }),
    exportUsers: get('/v1/admin/users/export-users/stages/:id'),
    exportMessages: get('/v1/admin/users/messages/group/:id'),
  },
  products: {
    getProductsByEvent: get('/v1/admin/products/event/:id'),
    getOne: get('/v1/admin/products/:id'),
    create: post('/v1/admin/products/create'),
    uploadAsset: post('/v1/admin/products/upload-asset', null, {
      'Content-Type': 'multipart/form-data',
    }),
    update: patch('/v1/admin/products/update/:id'),
    delete: del('/v1/admin/products/delete/:id'),
  },
})

export default apiRoutes
