import { rest } from 'msw'
import { user } from '../fake-data/users'
import config from '~/config'

const apiBaseUrl = `${config.api.baseURL}`

const me = rest.get(`${apiBaseUrl}/v1/admin/users/me`, (req, res, ctx) => {
  const sym = Object.getOwnPropertySymbols(req.headers)[0]
  // @ts-ignore
  const token = req?.headers[sym]?.authorization
  if (!token) {
    return res(ctx.status(401))
  }
  return res(ctx.status(200), ctx.json(user))
})
const updateAdmin = rest.patch(
  `${apiBaseUrl}/v1/admin/users/update-admin`,
  (_req, res, ctx) => res(ctx.status(200), ctx.json(user)),
)
const deleteAdminConfirmation = rest.delete(
  `${apiBaseUrl}/v1/admin/users/delete-admin-confirmation`,
  (_req, res, ctx) => res(ctx.status(200), ctx.json(true)),
)

export const usersHandler = [me, updateAdmin, deleteAdminConfirmation]
