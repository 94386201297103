export const events = [
  {
    isDeleted: false,
    isActive: false,
    _id: '1',
    name: 'Best Shopping  ',
    eventId: 'sami-hamaizi.gleamer.io',
    domain: 'gleamer.io',
    admin: '62ea8ad93efeab46001416da',
    otApiKey: '47560831',
    createdAt: '2022-08-25T11:08:37.113Z',
    updatedAt: '2022-08-26T11:39:38.357Z',
    __v: 0,
    id: '1',
  },
  {
    isDeleted: false,
    isActive: false,
    _id: '2',
    name: 'Good event name',
    eventId: 'samid-ddeded-dededaaaaaa.gleamer.io',
    domain: 'gleamer.io',
    admin: '62ea8ad93efeab46001416da',
    otApiKey: '47561661',
    createdAt: '2022-08-26T12:53:01.991Z',
    updatedAt: '2022-08-26T12:53:17.341Z',
    __v: 0,
    id: '2',
  },
]

export const emptyEvents: any[] = []
