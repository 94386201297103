export const parseBoolean = (value, defaultValue = false) => {
  if (value === undefined || value === null) {
    return defaultValue
  }

  return !/^(false|0)$/i.test(value) && !!value
}

const config = {
  app: {
    baseUrlDomain: process.env.NEXT_PUBLIC_BASE_URL_DOMAIN,
    prefix: process.env.NEXT_PUBLIC_DOMAIN_PREFIX,
  },
  api: {
    baseURL: process.env.NEXT_PUBLIC_API_BASE_URL,
  },
  stripe: {
    apiKey: process.env.NEXT_PUBLIC_STRIPE_API_KEY,
  },
  development: parseBoolean(process.env.NEXT_PUBLIC_DEVELOPMENT),
}

export default config
