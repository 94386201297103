export const user: any = {
  // type: 'broadcaster',
  groups: [
    {
      isRaiseHandEnabled: false,
      endEvent: false,
      _id: '62b02157682a391cc05634af',
      streamingType: 'conference',
      name: 'Sami Event',
      startDate: '2022-06-21T09:50:14.279Z',
      postVideoUrl: null,
      preVideoUrl:
        'https://live-designer.s3.amazonaws.com/trailer.mp4-1d35ddf1-259b-4fa2-ae07-d6b94627fec1',
      backgroundUrl: null,
      project: '62b020bc682a391cc056337a',
      sendbirdRoomId: 'SamiEvent7a0bf506843e4b4bb3566c9e4e4bb8ee',
      preVideoDuration: 52.208333,
      opentokSessionId:
        '1_MX40NzUyNTM2MX5-MTY1NTcyODA1MDE2OX5aZ1JRdDl2bDZTaU1sUjBMVExJeTVlNkl-fg',
      __v: 0,
    },
  ],
  agreedRules: false,
  cookiesConsent: false,
  isActive: true,
  region: 'global',
  type: 'admin',
  _id: '62b024f8682a391cc056408c',
  firstName: 'Test ',
  lastName: 'User',
  project: {
    _id: '62b020bc682a391cc056337a',
    name: 'Sami Armani',
    type: 'private',
    baseUrl: 'http://localhost:3001',
    ticketOffice: {
      isActive: true,
      _id: '62b020bc682a391cc056337b',
      price: null,
      maxTickets: 20,
    },
    otApiKey: '47525361',
    createdAt: '2022-06-20T07:24:44.974Z',
    updatedAt: '2022-06-20T07:24:44.974Z',
    __v: 0,
    id: '62b020bc682a391cc056337a',
  },
  authToken:
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYyYjAyNGY4NjgyYTM5MWNjMDU2NDA4YyIsImlhdCI6MTY1NTcxNzU5MX0.mH2UCe0X9bSEs9Lb431zp6Kkd50Pt2cPnaLec7GMH7Q',
  nickname: 'Sami  Viewer',
  id: '62b024f8682a391cc056408c',
  company: {
    name: 'Sami Company',
  },
  email: 'samihamaizi3645@gmail.com',
}
